import axios from "axios";
import useAuth from "./useAuth";
import useCatalogo from "./useCatalogo";

const apiSession = axios.create({
    baseURL: `${process.env.VUE_APP_SESSION_SERVICE}`,
    headers: {
        "Content-Type": "application/json",
    },
});

export default function useSession() {
    const { getCustomerSAP } = useAuth();
    const { items } = useCatalogo();

    const saveSession = async () => {
        if (getCustomerSAP())
            await apiSession.post("savesession", {
                customerSAP: getCustomerSAP(),
                data: {
                    items: items.value,
                },
            });
    };

    const getSession = async () => {
        if (getCustomerSAP()) {
            const response = await apiSession.post("getsession", {
                customerSAP: getCustomerSAP(),
            });

            if (
                response.data.status_code === 1 &&
                response.data?.session?.items
            ) {
                items.value = response.data?.session?.items;
            }
        }
    };

    const deleteSession = async (cs) => {
        await apiSession.delete(`deletesesion/${cs}`);
    };

    return { saveSession, getSession, deleteSession };
}
