<template>
    <div>
        <p class="list-msg fw-bolder">PRECIOS NO INCLUYEN IMPUESTOS</p>
        <div class="table-responsive">
            <table class="table align-middle">
                <thead>
                    <tr>
                        <th>PRODUCTO</th>
                        <th>PRECIO POR CAJA</th>
                        <th>UNIDADES POR CAJA</th>
                        <th>PRECIO POR UNIDAD</th>
                        <th>UNIDADES</th>
                        <th>SUB-TOTAL</th>
                    </tr>
                </thead>
                <tbody>
                    <ProductRow
                        v-for="i in filteredItems"
                        :key="i.sku"
                        :item="i"
                        :id="`sku-${i.sku}`"
                        :class="{ active: selectedItem?.sku === i.sku }"
                        @onUpdate="(item) => $emit('onUpdate', item)"
                    />
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { computed, onMounted } from "vue";
import ProductRow from "./ProductRow.vue";

export default {
    name: "ListAgroquimicos",
    components: { ProductRow },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        filter: [String, Array],
        selectedItem: Object,
    },
    setup(props) {
        const filteredItems = computed(() => {
            // Filter Items
            if (Array.isArray(props.filter)) {
                return props.items.filter((i) =>
                    props.filter.find((f) => f === i.category)
                );
            }
            return props.items.filter((i) => i.category === props.filter);
        });

        onMounted(() => {
            if (props.selectedItem) {
                const el = document.querySelector(
                    `#sku-${props.selectedItem?.sku}`
                );
                console.log("item:", el);
                setTimeout(() => {
                    if (el) el.scrollIntoView();
                }, 1000);
            }
        });

        return { filteredItems };
    },
};
</script>
<style lang="scss" scoped>
.list-msg,
th {
    font-size: 0.8em;
}
th {
    white-space: nowrap;
    text-align: center;
}
.table th:first-child,
.table td:first-child {
    position: sticky;
    left: 0;
    background-color: white;
}

.active {
    background-color: #f9f9f9 !important;
}
</style>
