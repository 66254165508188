import { reactive, watch } from "vue";
import useCatalogo from "./useCatalogo";

const resumen = reactive({ subtotal: 0, descuento: 0, total: 0 });

export default function useCarrito() {
    const { items } = useCatalogo();

    watch(
        () => items.value,
        (newVal) => {
            let sumSubTotal = 0;
            let sumTotalDiscount = 0;

            for (const i of newVal) {
                if (i.quantity) {
                    sumSubTotal += i.carrito.subTotal;
                    sumTotalDiscount += i.carrito.subTotalDiscount;
                }
            }
            resumen.subtotal = sumSubTotal;
            resumen.total = sumTotalDiscount;

            // if (sumTotalDiscount > 0) {
            resumen.descuento = resumen.subtotal - resumen.total;
            // } else {
            //     resumen.total = sumTotalDiscount;
            //     resumen.descuento = 0;
            // }
        }
    );
    return { resumen };
}
