<template>
    <div class="page-footer">
        <div class="footer">
            <div class="section aboutus py-5">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-8">
                            <h1 class="mb-4 text-light fs-6">
                                ACERCA DE NOSOTROS
                            </h1>
                            <p
                                class="text-light fw-light text-justify"
                                align="justify"
                            >
                                Cambiagro es una plataforma digital diseñada
                                para aquellos miembros del mundo agrícola con
                                una visión de desarrollo a través del mundo
                                digital. Cambiagro ofrece las funcionalidades,
                                los productos y el conocimiento experto, para
                                alcanzar la excelencia en los cultivos y obtener
                                los mejores resultados financieros en su agro
                                negocio. Es el espacio en el que ponemos a su
                                disposición los recursos y las herramientas para
                                hacer crecer su agro negocio.
                            </p>
                        </div>
                        <div class="col-lg-4 text-end">
                            <img
                                src="https://storage.googleapis.com/assets_cambiagro/assets/logocambiagrofooter.png"
                                class="img-fluid mb-5"
                                alt=""
                                srcset=""
                            />
                            <p class="text-light fw-light fs-8">
                                Copyright © 2023 Disagro. Todos los derechos
                                reservados.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "WebFooter",
};
</script>
<style lang="scss"></style>
