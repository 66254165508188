<template>
    <VueFinalModal
        @click="$emit('onClose')"
        class="confirm-modal"
        content-class="confirm-modal-content"
        overlay-transition="vfm-fade"
        content-transition="vfm-fade"
        ><div class="modal show d-block" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="position-absolute end-0">
                        <div>
                            <i
                                role="button"
                                class="fa fa-2x fa-times me-1"
                                @click="$emit('onClose')"
                            ></i>
                        </div>
                    </div>
                    <img
                        src="@/assets/img/Pop_Up_2024.gif"
                        alt=""
                        srcset=""
                        class="img-fluid"
                    />
                </div>
            </div>
        </div>
    </VueFinalModal>
</template>
<script>
import { VueFinalModal } from "vue-final-modal";
export default {
    name: "PopupModal",
    components: { VueFinalModal },
};
</script>
<style lang="scss" scoped>
i {
    color: #23232387;
}
</style>
