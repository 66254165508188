<template>
    <web-header class="main-header position-fixed bg-white" />
    <router-view class="main-content" />
    <web-footer class="mt-auto" />
    <ModalsContainer />
</template>
<script>
import { onMounted, watch } from "vue";
import WebHeader from "./components/web-structure/WebHeader.vue";
import WebFooter from "./components/web-structure/WebFooter.vue";
import { ModalsContainer } from "vue-final-modal";
import useSession from "./services/useSession";
import useCatalogo from "./services/useCatalogo";
import useAuth from "./services/useAuth";

import "vue-final-modal/style.css";

export default {
    name: "FeriaCambiagro",
    components: { WebHeader, WebFooter, ModalsContainer },
    setup() {
        const auth = useAuth();
        const { saveSession, getSession } = useSession();
        const { items } = useCatalogo();

        onMounted(async () => {
            if (auth.getCustomerSAP()) {
                await getSession();
            }
        });

        watch(
            () => items.value,
            () => {
                saveSession();
            }
        );
    },
};
</script>
<style lang="scss">
@import url("@/assets/fonts/mark-pro/mark-pro.css");
@import "@/assets/styles/cambiagro.scss";
@import url("@/assets/fonts/fontawesome/css/all.min.css");
#app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.main-header {
    z-index: 1;
}
.main-content {
    padding-top: 105px;
}

.lh-reset {
    height: 1em;
}

@media (min-width: 1088px) {
    .main-content {
        padding-top: 113px;
    }
}
</style>
