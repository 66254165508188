export function divisaFormat(value) {
    let _value = 0;
    if (!isNaN(value)) {
        _value = value;
    }

    return new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(_value);
}
