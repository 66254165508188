<template>
    <tr>
        <td class="text-center">
            <div
                class="column-product d-flex flex-column flex-lg-row align-items-center justify-content-certer text-center"
            >
                <div
                    class="product-img mb-1 mb-lg-0"
                    :style="{
                        'background-image': `url(${item.image})`,
                    }"
                ></div>
                <div class="product-title text-center text-lg-start">
                    <strong>{{ item.name }}</strong
                    ><br />
                    <span
                        class="badge product-category"
                        :class="item.category"
                        >{{ item.category }}</span
                    >
                </div>
            </div>
        </td>
        <td class="text-center">
            <span
                class="text-primary text-center"
                v-if="item.quantity > 0 && pricePerBoxDiscount !== pricePerBox"
                >{{ divisaFormat(pricePerBoxDiscount) }}</span
            >
            <br
                v-if="item.quantity > 0 && pricePerBoxDiscount !== pricePerBox"
            />
            <span :class="originalPriceClasses">{{
                divisaFormat(pricePerBox)
            }}</span>
        </td>
        <td>{{ unitPerBox }}</td>
        <td class="text-center">
            <span
                class="text-primary text-center"
                v-if="item.quantity > 0 && priceDiscount !== price"
                >{{ divisaFormat(priceDiscount) }}</span
            >
            <br v-if="item.quantity > 0 && priceDiscount !== price" />
            <span :class="originalPriceClasses">{{ divisaFormat(price) }}</span>
        </td>
        <td class="text-center">
            <div
                class="d-flex flex-column align-items-center justify-content-center"
            >
                <span
                    v-if="item.sector === 'PC' && item.stock <= 0"
                    class="text-warning"
                    >No Disponible</span
                >
                <input
                    v-else
                    v-model="internalItem.quantity"
                    type="number"
                    size="4"
                    :step="unitPerBox"
                    title="Cantidad"
                    class="form-control form-control-sm rounded-pill mx-0 mx-lg-1 text-center quantity-input"
                    :class="{ 'is-invalid': errorStatus, 'mb-1': errorStatus }"
                    :min="0"
                    :max="item.stock"
                    @focusout="resetError"
                    @focusin="() => (inputFocusState = true)"
                    @input="onChange"
                />
                <span v-if="errorStatus" class="text-danger fs-8">{{
                    errorMsg
                }}</span>
            </div>
        </td>
        <td class="subtotalColumn text-center">
            <span
                class="text-primary"
                v-if="item.quantity > 0 && subTotalDiscount !== subTotal"
                >{{ divisaFormat(subTotalDiscount) }}</span
            >
            <br v-if="item.quantity > 0 && subTotalDiscount !== subTotal" />
            <span :class="originalPriceClasses">{{
                divisaFormat(subTotal)
            }}</span>
        </td>
    </tr>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { computed, watch, ref } from "vue";
import useAuth from "@/services/useAuth";
import { divisaFormat } from "@/services/useDivisas";
import useDescuento from "@/services/useDescuento";

export default {
    name: "ProductRow",
    props: { item: Object },
    setup(props, { emit }) {
        const { getUser } = useAuth();
        const { escalaDescuento } = useDescuento();
        const user = getUser();
        const listaPrecio = computed(() => {
            switch (props.item.sector) {
                case "PC":
                    return user.feria.lista_ppc;
                case "ER":
                case "GC":
                case "NC":
                    return user.feria.lista_pnc;
                default:
                    return "";
            }
        });
        const discount = computed(() => {
            return 0;
            // if (
            //     Number.isNaN(
            //         escalaDescuento.value[props.item.sector][
            //             props.item.category
            //         ]
            //     )
            // ) {
            //     return 0;
            // }
            // return props.item.custom_attributes[0].discount[
            //     escalaDescuento.value[props.item.sector][props.item.category]
            // ];
        });

        const price = computed(() => {
            // if (props.item.custom_attributes[0].priceperunit) {
            //     return props.item.custom_attributes[0].priceperunit[
            //         listaPrecio.value
            //     ];
            // }
            return pricePerBox.value / unitPerBox.value;
        });
        const priceDiscount = computed(() => {
            console.log("discount.value", discount.value);
            if (
                discount.value > 0 &&
                escalaDescuento.value[props.item.sector][props.item.category]
            ) {
                return Math.trunc(price.value * ((100 - discount.value) / 100));
            }
            return price.value;
        });
        const unitPerBox = computed(() => {
            return props.item.custom_attributes[0].unitperbox;
        });
        const pricePerBox = computed(() => {
            return props.item.custom_attributes[0].price[listaPrecio.value];
        });
        const pricePerBoxDiscount = computed(() => {
            if (
                discount.value > 0 &&
                escalaDescuento.value[props.item.sector][props.item.category]
            ) {
                return Math.trunc(
                    pricePerBox.value * ((100 - discount.value) / 100)
                );
            }
            return pricePerBox.value;
        });

        const subTotal = computed(() => {
            return price.value * props.item.quantity;
        });

        const subTotalDiscount = computed(() => {
            if (
                discount.value > 0 &&
                escalaDescuento.value[props.item.sector][props.item.category]
            ) {
                return Math.trunc(
                    subTotal.value * ((100 - discount.value) / 100)
                );
            }
            return subTotal.value;
        });

        const internalItem = computed({
            get() {
                return props.item;
            },
            set(value) {
                emit("onUpdate", value);
            },
        });
        const source = computed(() => {
            switch (props.item.sector) {
                case "PC":
                    return user.feria.despacho_ppc;
                case "NC":
                    return user.feria.despacho_pnc;
                case "ER":
                    return "7P18";
                case "GC":
                    return "7P17";
            }
            return "";
        });

        // eslint-disable-next-line no-unused-vars
        const handleUpdateItem = (quantity) => {
            internalItem.value.carrito = {
                price: price.value,
                priceDiscount: priceDiscount.value,
                unitPerBox: unitPerBox.value,
                pricePerBox: pricePerBox.value,
                pricePerBoxDiscount: pricePerBoxDiscount.value,
                subTotal: subTotal.value,
                subTotalDiscount: subTotalDiscount.value,
                discount: discount.value,
                source: source.value,
            };
            console.log(internalItem.value.name, {
                ...internalItem.value.carrito,
            });
            emit("onUpdate", { ...internalItem.value, quantity });
        };

        // eslint-disable-next-line no-unused-vars
        const timeout = ref();
        const inputFocusState = ref(false);

        const errorStatus = ref(false);
        const errorMsg = ref("");

        const resetError = () => {
            inputFocusState.value = false;
            setTimeout(() => {
                errorStatus.value = false;
                errorMsg.value = "";
            }, 2300);
        };

        const onChange = () => {
            // console.log("value:", value);

            if (timeout.value) {
                clearInterval(timeout.value);
                timeout.value = undefined;
            }

            timeout.value = setTimeout(() => {
                if (
                    internalItem.value.quantity > props.item.stock &&
                    props.item.virtualstock < 999999
                ) {
                    // if (inputFocusState.value) {
                    errorStatus.value = true;
                    errorMsg.value = "Stock insuficiente";
                    // }
                    handleUpdateItem(undefined);
                } else if (
                    isNaN(parseInt(internalItem.value.quantity)) ||
                    (internalItem.value.quantity < unitPerBox.value &&
                        internalItem.value.quantity > 0) ||
                    internalItem.value.quantity % unitPerBox.value !== 0
                ) {
                    // if (inputFocusState.value) {
                    errorStatus.value = true;
                    errorMsg.value =
                        "La cantidad debe ser múltiplo de " + unitPerBox.value;
                    // }
                    handleUpdateItem(undefined);
                } else if (internalItem.value.quantity <= 0) {
                    handleUpdateItem(undefined);
                } else {
                    errorStatus.value = false;
                    errorMsg.value = "";
                    handleUpdateItem(internalItem.value.quantity);
                }
                timeout.value = undefined;
            }, 1000);
        };

        // watch(
        //     () => internalItem.value.quantity,
        //     (nVal, oVal) => {
        //         if (timeout.value) {
        //             clearInterval(timeout.value);
        //             timeout.value = undefined;
        //         }
        //         timeout.value = setTimeout(() => {
        //             if (nVal === oVal) {
        //                 return;
        //             }

        //             errorStatus.value = false;
        //             errorMsg.value = "";
        //             if (nVal > props.item.stock) {
        //                 errorStatus.value = true;
        //                 errorMsg.value = "Stock insuficiente";
        //                 internalItem.value.quantity = null;
        //             } else {
        //                 if (
        //                     isNaN(parseInt(nVal)) ||
        //                     nVal < unitPerBox.value ||
        //                     nVal % unitPerBox.value !== 0
        //                 ) {
        //                     errorStatus.value = true;
        //                     errorMsg.value =
        //                         "La cantidad debe ser múltiplo de " +
        //                         unitPerBox.value;
        //                     internalItem.value.quantity = null;
        //                 }
        //             }
        //             handleUpdateItem();
        //             internalItem.value.quantity = nVal;
        //             timeout.value = undefined;
        //         }, 1000);
        //     }
        // );

        const originalPriceClasses = computed(() => {
            if (
                props.item.quantity > 0 &&
                escalaDescuento.value[props.item.sector][props.item.category] &&
                priceDiscount.value !== price.value
            ) {
                return "text-decoration-line-through fs-7";
            }
            return "";
        });

        return {
            internalItem,
            price,
            priceDiscount,
            unitPerBox,
            pricePerBox,
            pricePerBoxDiscount,
            subTotal,
            subTotalDiscount,
            divisaFormat,
            escalaDescuento,
            originalPriceClasses,
            urlImg: "https://storage.googleapis.com/assets_cambiagro/assets/images/",
            errorStatus,
            errorMsg,
            resetError,
            onChange,
            inputFocusState,
        };
    },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/cambiagro.scss";
.table th:first-child,
.table td:first-child {
    position: sticky;
    left: 0;
    background-color: white;
    border-right: 1px solid $gray-300;
}
.column-product {
    min-width: 90px;
    max-width: calc(40vw);
}
.product-title .product-category {
    color: white;
    font-size: 0.6em;
    padding: 2px 8px 2px 8px;
}

.product-category.SIMPLE,
.product-category.SOLUBLES {
    background-color: #00934a;
}

.product-category.VOLUMEN,
.product-category.FOLIARES {
    border: 1px solid green;
    color: green;
}
.product-category.FERTICROP {
    background-color: #e8db34;
    color: #775423;
}

.product-category.NITROXTEND {
    background-color: #004891;
    color: #e3e3e3;
}

.product-category.RIEGOS {
    background-color: #9a00ff;
}

.product-category.DESCUENTO {
    background-color: #ff7300;
}
.product-category.ESTRATÉGICO {
    background-color: #00c127;
}
.product-category.SEMILLAS {
    background-color: #4a2e21;
}

.product-img {
    width: 55px;
    height: 55px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.product-title,
td {
    font-size: 0.8rem;
    text-align: center;
}

.product-title {
    line-height: 13px;
    text-align: left;
}

td input {
    max-width: 130px;
}

.subtotalColumn {
    min-width: 200px;
}

.quantity-input {
    min-width: 110px;
}

@media (min-width: 1088px) {
    .product-title,
    td {
        font-size: 0.9rem;
    }
    .column-product {
        max-width: inherit;
    }
    .subtotalColumn {
        min-width: 150px;
    }
}
</style>
