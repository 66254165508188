<template>
    <div class="w-100 nav-container mb-3">
        <div class="d-flex justify-content-center mb-1">
            <HeaderCarousel />
        </div>
        <ul class="nav nav-tabs">
            <li
                class="nav-item"
                :role="modelValue === 'volumen' ? '' : 'button'"
                @click="handleTabClicked('volumen')"
            >
                <span
                    class="nav-link"
                    :class="{ active: modelValue === 'volumen' }"
                    >Alta rotación</span
                >
            </li>
            <li
                class="nav-item"
                :role="modelValue === 'nuevos' ? '' : 'button'"
                @click="handleTabClicked('nuevos')"
            >
                <span
                    class="nav-link"
                    :class="{ active: modelValue === 'nuevos' }"
                    >Especialidad</span
                >
            </li>
            <div class="ms-auto">
                <!-- <span id="texto_unidades">
                    Por favor, ingrese valores en el campo
                    <strong>"UNIDADES"</strong> que sean múltiplos de las
                    "Unidades por caja" o "Unidades por tonelada"
                </span> -->
            </div>
            <div class="ms-auto">
                <router-link
                    to="/checkout"
                    class="btn btn-sm btn-primary text-white btn-checkout"
                >
                    Continuar
                    <i class="ms-1 fa fa-arrow-from-left"></i>
                </router-link>
            </div>
        </ul>
    </div>
</template>
<script>
import HeaderCarousel from "@/components/web-structure/HeaderCarousel.vue";

export default {
    name: "ProductListNav",
    components: { HeaderCarousel },
    props: {
        modelValue: String,
    },
    setup(props, { emit }) {
        const handleTabClicked = (value) => {
            emit("update:modelValue", value);
        };
        return { handleTabClicked };
    },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/cambiagro.scss";
.nav-link {
    font-size: 0.87em;
    padding-inline: 0.65em;
}
.btn-checkout {
    font-size: 0.78rem;
}

@media (min-width: 1088px) {
    .nav-link {
        font-size: 1em;
        padding-inline: $nav-link-padding-y;
    }
    .btn-checkout {
        font-size: 1rem;
    }
}
</style>
