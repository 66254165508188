<template>
    <div class="text-center" style="height: calc(75vh)">
        <!-- <LoaderAnimation :data="'logo_sembradores.json'"
            ></LoaderAnimation
        > -->
        <img
            src="@/assets/Logo_Sembradores_Sin_fondo.png"
            style="width: calc(50vw)"
        />
        <h1>{{ name }}</h1>
    </div>
</template>
<script>
import { computed, onMounted } from "vue";
import useAuth from "@/services/useAuth";
import { useRouter } from "vue-router";
import useSession from "@/services/useSession";
// import LoaderAnimation from "@/components/LoaderAnimation.vue";

export default {
    name: "LoginView",
    // components: { LoaderAnimation },
    props: {
        customerId: String,
        token: String,
    },
    setup(props) {
        const auth = useAuth();
        const router = useRouter();

        const session = useSession();

        const timeOut = () =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 4000);
            });

        const name = computed(() => {
            return auth.user.value?.nombre_compania;
        });

        const initSession = () =>
            // eslint-disable-next-line no-async-promise-executor
            new Promise(async (resolve, reject) => {
                try {
                    if (!props.customerId && auth.checkSession()) {
                        console.log("Datos Encontrados");
                        if (props.token) auth.setToken(props.token);
                    } else {
                        console.log("CreandoSession");
                        await auth.setupSession(props.customerId);
                        await session.getSession();
                    }
                    resolve();
                } catch (error) {
                    reject(error.message);
                }
            });

        onMounted(async () => {
            try {
                await Promise.all([timeOut(), initSession()]);
                router.push("/fertilizantes");
            } catch (error) {
                console.log("error al crear la session");
                auth.closeSession();
            }
        });

        return { name };
    },
};
</script>
<style lang="scss" scoped></style>
